import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toTIFFFutRawPctIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.Dlr_L = obj.F_Dlr_L / obj.F_OI;
      newObj.Dlr_S = -obj.F_Dlr_S / obj.F_OI;
      newObj.Dlr_Sprd = obj.F_Dlr_Sprd / obj.F_OI;
      newObj.AssMgr_L = obj.F_AssMgr_L / obj.F_OI;
      newObj.AssMgr_S = -obj.F_AssMgr_S / obj.F_OI;
      newObj.AssMgr_Sprd = obj.F_AssMgr_Sprd / obj.F_OI;
      newObj.LevMny_L = obj.F_LevMny_L / obj.F_OI;
      newObj.LevMny_S = -obj.F_LevMny_S / obj.F_OI;
      newObj.LevMny_Sprd = obj.F_LevMny_Sprd / obj.F_OI;
      newObj.OtrRept_L = obj.F_OtrRept_L / obj.F_OI;
      newObj.OtrRept_S = -obj.F_OtrRept_S / obj.F_OI;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd / obj.F_OI;
      newObj.NR_L = obj.F_NR_L / obj.F_OI;
      newObj.NR_S = -obj.F_NR_S / obj.F_OI;
    } else {
      newObj.Dlr_L =
        obj.F_Dlr_L /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.Dlr_S =
        -obj.F_Dlr_S /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.Dlr_Sprd = [];
      newObj.AssMgr_L =
        obj.F_AssMgr_L /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.AssMgr_S =
        -obj.F_AssMgr_S /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.AssMgr_Sprd = [];
      newObj.LevMny_L =
        obj.F_LevMny_L /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.LevMny_S =
        -obj.F_LevMny_S /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.LevMny_Sprd = [];
      newObj.OtrRept_L =
        obj.F_OtrRept_L /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.OtrRept_S =
        -obj.F_OtrRept_S /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_L =
        obj.F_NR_L /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
      newObj.NR_S =
        -obj.F_NR_S /
        (obj.F_OI -
          obj.F_Dlr_Sprd -
          obj.F_AssMgr_Sprd -
          obj.F_LevMny_Sprd -
          obj.F_OtrRept_Sprd);
    }
    return newObj;
  });
  return fmtIdx(Idx, period);
}
