import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toLegCmbRawPctIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.NC_L = obj.C_NC_L / obj.C_OI;
      newObj.NC_S = -obj.C_NC_S / obj.C_OI;
      newObj.NC_Sprd = obj.C_NC_Sprd / obj.C_OI;
      newObj.CM_L = obj.C_CM_L / obj.C_OI;
      newObj.CM_S = -obj.C_CM_S / obj.C_OI;
      newObj.NR_L = obj.C_NR_L / obj.C_OI;
      newObj.NR_S = -obj.C_NR_S / obj.C_OI;
      newObj.NC_Sprd = obj.C_NC_Sprd / obj.C_OI;
    } else {
      newObj.NC_L = obj.C_NC_L / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NC_S = -obj.C_NC_S / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NC_Sprd = [];
      newObj.CM_L = obj.C_CM_L / (obj.C_OI - obj.C_NC_Sprd);
      newObj.CM_S = -obj.C_CM_S / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NR_L = obj.C_NR_L / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NR_S = -obj.C_NR_S / (obj.C_OI - obj.C_NC_Sprd);
    }
    return newObj;
  });
  return fmtIdx(Idx, period);
}
