const allCategories = [
  { symbol: "Canadian Dollar", ticker: "6C", code: "090741", type: "TIFF" },
  { symbol: "Swiss Franc", ticker: "6S", code: "092741", type: "TIFF" },
  { symbol: "British Pound", ticker: "6B", code: "096742", type: "TIFF" },
  { symbol: "Japanese Yen", ticker: "6J", code: "097741", type: "TIFF" },
  { symbol: "Euro Dollar", ticker: "6E", code: "099741", type: "TIFF" },
  { symbol: "Australian Dollar", ticker: "6A", code: "232741", type: "TIFF" },
  { symbol: "New Zealand Dollar", ticker: "6N", code: "112741", type: "TIFF" },
  { symbol: "US Dollar Index", ticker: "DX", code: "098662", type: "TIFF" },
  { symbol: "Mexican Peso", ticker: "6M", code: "095741", type: "TIFF" },
  { symbol: "Brazilian Real", ticker: "6L", code: "102741", type: "TIFF" },
  { symbol: "South African Rand", ticker: "6Z", code: "122741", type: "TIFF" },
  { symbol: "S&P 500 E-mini", ticker: "ES", code: "13874A", type: "TIFF" },
  { symbol: "S&P 400 E-mini", ticker: "EMD", code: "33874A", type: "TIFF" },
  { symbol: "Nasdaq 100 E-mini", ticker: "NQ", code: "209742", type: "TIFF" },
  {
    symbol: "E-mini Dow Jones($5)",
    ticker: "YM",
    code: "124603",
    type: "TIFF",
  },
  { symbol: "S&P 500 VIX", ticker: "VX", code: "1170E1", type: "TIFF" },
  { symbol: "Nikkei 225", ticker: "NKD", code: "240741", type: "TIFF" },
  {
    symbol: "E-mini Russell 2000",
    ticker: "RTY",
    code: "239742",
    type: "TIFF",
  },
  { symbol: "30 Day FED Funds", ticker: "ZQ", code: "045601", type: "TIFF" },
  // { symbol: "3 Month Eurodollar", ticker: "GE", code: "132741", type: "TIFF" },
  { symbol: "US 2 Year T-Note", ticker: "ZT", code: "042601", type: "TIFF" },
  { symbol: "US 5 Year T-Note", ticker: "ZF", code: "044601", type: "TIFF" },
  { symbol: "US 10 Year T-Note", ticker: "ZN", code: "043602", type: "TIFF" },
  { symbol: "US 10 Year T-note", ticker: "TN", code: "043607", type: "TIFF" },
  { symbol: "US 30 Year T-Bond", ticker: "ZB", code: "020601", type: "TIFF" },
  { symbol: "Ultra US T-Bond", ticker: "UB", code: "020604", type: "TIFF" },
  { symbol: "Bitcoin", ticker: "BTC", code: "133741", type: "TIFF" },
  { symbol: "Ethereum", ticker: "ETH", code: "146021", type: "TIFF" },
  { symbol: "Gold", ticker: "GC", code: "088691", type: "DisAgg" },
  { symbol: "Silver", ticker: "SI", code: "084691", type: "DisAgg" },
  { symbol: "Copper #1", ticker: "HG", code: "085692", type: "DisAgg" },
  { symbol: "Palladium", ticker: "PA", code: "075651", type: "DisAgg" },
  { symbol: "Platinum", ticker: "PL", code: "076651", type: "DisAgg" },
  { symbol: "WTI Crude Oil", ticker: "CL", code: "067651", type: "DisAgg" },
  { symbol: "Brent Crude Oil", ticker: "BZ", code: "06765T", type: "DisAgg" },
  { symbol: "RBOB Gasoline", ticker: "RB", code: "111659", type: "DisAgg" },
  { symbol: "Natural Gas", ticker: "NG", code: "023651", type: "DisAgg" },
  { symbol: "Heating Oil", ticker: "HO", code: "022651", type: "DisAgg" },
  { symbol: "Cocoa", ticker: "CC", code: "073732", type: "DisAgg", CITS: true },
  {
    symbol: "Cotton #2",
    ticker: "CT",
    code: "033661",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Coffee C ®",
    ticker: "KC",
    code: "083731",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Sugar #11",
    ticker: "SB",
    code: "080732",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Frozen Concentrate Orange Juice A",
    ticker: "OJ",
    code: "040701",
    type: "DisAgg",
  },
  // { symbol: "Random Length Lumber", ticker: "LBS", code: "058643", type: "DisAgg" },
  {
    symbol: "Soybean",
    ticker: "ZS",
    code: "005602",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Soybean Oil",
    ticker: "ZL",
    code: "007601",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Soybean Meal",
    ticker: "ZM",
    code: "026603",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Wheat-SRW",
    ticker: "ZW",
    code: "001602",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Wheat-HRW(Kansas)",
    ticker: "KE",
    code: "001612",
    type: "DisAgg",
    CITS: true,
  },
  { symbol: "Wheat-HRS(Minn.)", ticker: "QMW", code: "001626", type: "DisAgg" },
  { symbol: "Oats", ticker: "ZO", code: "004603", type: "DisAgg" },
  { symbol: "Corn", ticker: "ZC", code: "002602", type: "DisAgg", CITS: true },
  { symbol: "Rough Rice", ticker: "ZR", code: "039601", type: "DisAgg" },
  {
    symbol: "Lean Hogs",
    ticker: "HE",
    code: "054642",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Live Cattle",
    ticker: "LE",
    code: "057642",
    type: "DisAgg",
    CITS: true,
  },
  {
    symbol: "Feeder Cattle",
    ticker: "GF",
    code: "061641",
    type: "DisAgg",
    CITS: true,
  },
];
export default allCategories;
