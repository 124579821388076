export function fmtXAxisPointerDate(params) {
  const dateParts = params.value.split("-");
  const year = dateParts[2];
  const month = new Date(
    dateParts[2],
    parseInt(dateParts[0], 10) - 1,
    dateParts[1]
  ).toLocaleString("default", { month: "short" });
  const day = dateParts[1];
  return `${month} ${day}, ${year}`;
}
