import axios from "axios";
import { BaseURL, Timeout } from "./config";

class GRequest {
  constructor(baseURL = BaseURL, timeout = Timeout) {
    this.instance = axios.create({ baseURL, timeout });
  }
  req(config) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  get(config) {
    return this.req({ ...config, method: "get" });
  }
  post(config) {
    return this.req({ ...config, method: "post" });
  }
}

export default new GRequest();
