const digitsMap = {
  GC: 0,
  SI: 1,
  HG: 2,
  PA: 0,
  PL: 0,
  "6C": 3,
  "6S": 3,
  "6B": 3,
  "6J": 4,
  "6E": 3,
  "6A": 3,
  "6N": 3,
  DX: 1,
  "6M": 3,
  "6L": 3,
  "6Z": 3,
  CL: 1,
  BZ: 1,
  RB: 2,
  NG: 2,
  HO: 2,
  ES: 0,
  EMD: 0,
  NQ: 0,
  YM: 0,
  VX: 1,
  NKD: 0,
  RTY: 0,
  ZQ: 2,
  GE: 2,
  ZT: 1,
  ZF: 1,
  ZN: 1,
  TN: 1,
  ZB: 1,
  UB: 0,
  CC: 0,
  CT: 0,
  KC: 2,
  SB: 1,
  OJ: 0,
  LBS: 0,
  ZS: 0,
  ZL: 0,
  ZM: 0,
  ZW: 0,
  KE: 0,
  QMW: 0,
  ZO: 0,
  ZC: 0,
  ZR: 1,
  HE: 0,
  LE: 0,
  GF: 0,
  BTC: 0,
  ETH: 0,
};
export default digitsMap;
