import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toLegCmbNetPctVal(list, isContainSprd) {
  let Leg;
  if (isContainSprd) {
    Leg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.CM_N = (obj.C_CM_L - obj.C_CM_S) / obj.C_OI;
      newObj.NC_N = (obj.C_NC_L - obj.C_NC_S) / obj.C_OI;
      newObj.NR_N = (obj.C_NR_L - obj.C_NR_S) / obj.C_OI;
      newObj.NC_Sprd = obj.C_NC_Sprd / obj.C_OI;
      return newObj;
    });
  } else {
    Leg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.CM_N = (obj.C_CM_L - obj.C_CM_S) / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NC_N = (obj.C_NC_L - obj.C_NC_S) / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NR_N = (obj.C_NR_L - obj.C_NR_S) / (obj.C_OI - obj.C_NC_Sprd);
      newObj.NC_Sprd = [];
      return newObj;
    });
  }
  return fmtCOT(Leg);
}
