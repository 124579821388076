import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toLegFutRawPosVal(list, isContainSprd) {
  const Leg = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.F_OI;
      newObj.NC_Sprd = obj.F_NC_Sprd;
    } else {
      newObj.OI = obj.F_OI - obj.F_NC_Sprd;
      newObj.NC_Sprd = [];
    }
    newObj.CM_L = obj.F_CM_L;
    newObj.CM_S = -obj.F_CM_S;
    newObj.NC_L = obj.F_NC_L;
    newObj.NC_S = -obj.F_NC_S;
    newObj.NR_L = obj.F_NR_L;
    newObj.NR_S = -obj.F_NR_S;
    return newObj;
  });
  return fmtCOT(Leg);
}
