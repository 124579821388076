import { storeToRefs } from "pinia";
import useChartData from "@/stores/chartData";

export function fmtSeriesLabel(params) {
  const chartDataStore = useChartData();
  const { seriesType, valueType } = storeToRefs(chartDataStore);
  const nameAbbr = {
    "Open Interest": "OI",
    "OI On Volume": "OI",
    Commercial: "CM",
    "Commercial Long": "CM_L",
    "Commercial Short": "CM_S",
    "Non-commercial": "NC",
    "Non-commercial Long": "NC_L",
    "Non-commercial Short": "NC_S",
    "Non-commercial Spreads": "NC_Sprd",
    "Non-reportable": "NR",
    "Non-reportable Long": "NR_L",
    "Non-reportable Short": "NR_S",
    "Producer/Merchant/Processor/User": "P/M/P/U",
    "Producer/Merchant/Processor/User Long": "P/M/P/U_L",
    "Producer/Merchant/Processor/User Short": "P/M/P/U_S",
    "Swap Dealers": "SD",
    "Swap Dealers Long": "SD_L",
    "Swap Dealers Short": "SD_S",
    "Swap Dealers Spreads": "SD_Sprd",
    "Managed Money": "MM",
    "Managed Money Long": "MM_L",
    "Managed Money Short": "MM_S",
    "Managed Money Spreads": "MM_Sprd",
    "Other Reportables": "OR",
    "Other Reportables Long": "OR_L",
    "Other Reportables Short": "OR_S",
    "Other Reportables Spreads": "OR_Sprd",
    "Dealer Intermediary": "DI",
    "Dealer Intermediary Long": "DI_L",
    "Dealer Intermediary Short": "DI_S",
    "Dealer Intermediary Spreads": "DI_Sprd",
    "Asset Manager/Institutional": "AM/I",
    "Asset Manager/Institutional Long": "AM/I_L",
    "Asset Manager/Institutional Short": "AM/I_S",
    "Asset Manager/Institutional Spreads": "AM/I_Sprd",
    "Leveraged Funds": "LF",
    "Leveraged Funds Long": "LF_L",
    "Leveraged Funds Short": "LF_S",
    "Leveraged Funds Spreads": "LF_Sprd",
    "Commodity Index Trader": "CIT",
    "Commodity Index Trader Long": "CIT_L",
    "Commodity Index Trader Short": "CIT_S",
  };
  if (seriesType.value === "Pct" && valueType.value === "Val") {
    return (
      nameAbbr[params.seriesName] + ": " + (params.value * 100).toFixed(2) + "%"
    );
  } else {
    return nameAbbr[params.seriesName] + ": " + params.value;
  }
}
