import fmtVPs from "./fmtVPs";

export function toVPs(list, offset) {
  const VPList = list.slice(-offset).map((obj) => {
    let date = new Date(obj.Date * 1000);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;
    return [formattedDate, obj.Open, obj.Close, obj.Low, obj.High, obj.Volume];
  });
  return fmtVPs(VPList);
}
