import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toDisAggFutRawPctVal(list, isContainSprd) {
  let DisAgg;
  if (isContainSprd) {
    DisAgg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Prod_L = obj.F_Prod_L / obj.F_OI;
      newObj.Prod_S = -obj.F_Prod_S / obj.F_OI;
      newObj.Swap_L = obj.F_Swap_L / obj.F_OI;
      newObj.Swap_S = -obj.F_Swap_S / obj.F_OI;
      newObj.Swap_Sprd = obj.F_Swap_Sprd / obj.F_OI;
      newObj.M_Mny_L = obj.F_M_Mny_L / obj.F_OI;
      newObj.M_Mny_S = -obj.F_M_Mny_S / obj.F_OI;
      newObj.M_Mny_Sprd = obj.F_M_Mny_Sprd / obj.F_OI;
      newObj.OtrRept_L = obj.F_OtrRept_L / obj.F_OI;
      newObj.OtrRept_S = -obj.F_OtrRept_S / obj.F_OI;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd / obj.F_OI;
      newObj.NR_L = obj.F_NR_L / obj.F_OI;
      newObj.NR_S = -obj.F_NR_S / obj.F_OI;
      return newObj;
    });
  } else {
    DisAgg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Prod_L =
        obj.F_Prod_L /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Prod_S =
        -obj.F_Prod_S /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Swap_L =
        obj.F_Swap_L /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Swap_S =
        -obj.F_Swap_S /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Swap_Sprd = [];
      newObj.M_Mny_L =
        obj.F_M_Mny_L /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.M_Mny_S =
        -obj.F_M_Mny_S /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_L =
        obj.F_OtrRept_L /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.OtrRept_S =
        -obj.F_OtrRept_S /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_L =
        obj.F_NR_L /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.NR_S =
        -obj.F_NR_S /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      return newObj;
    });
  }
  return fmtCOT(DisAgg);
}
