function toVPs(rawData) {
  let dates = [];
  let prices = [];
  let volumes = [];
  for (let i = 0; i < rawData.length; i++) {
    dates.push(rawData[i].splice(0, 1)[0]);
    prices.push(rawData[i]);
    volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
  }
  return {
    Dates: dates,
    Prices: prices,
    Volumes: volumes,
  };
}
export default toVPs;
