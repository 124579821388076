import fmtLSR from "./fmtLSR";
import toDates from "./toDates";

export function toTIFFOptLSRVal(list) {
  let LSR;
  LSR = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (obj.O_Dlr_L > obj.O_Dlr_S) {
      if (obj.O_Dlr_S === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = obj.O_Dlr_L / obj.O_Dlr_S;
      }
    } else if (obj.O_Dlr_L < obj.O_Dlr_S) {
      if (obj.O_Dlr_L === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = -(obj.O_Dlr_S / obj.O_Dlr_L);
      }
    } else {
      newObj.Dlr_R = 1;
    }

    if (obj.O_AssMgr_L > obj.O_AssMgr_S) {
      if (obj.O_AssMgr_S === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = obj.O_AssMgr_L / obj.O_AssMgr_S;
      }
    } else if (obj.O_AssMgr_L < obj.O_AssMgr_S) {
      if (obj.O_AssMgr_L === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = -(obj.O_AssMgr_S / obj.O_AssMgr_L);
      }
    } else {
      newObj.AssMgr_R = 1;
    }

    if (obj.O_LevMny_L > obj.O_LevMny_S) {
      if (obj.O_LevMny_S === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = obj.O_LevMny_L / obj.O_LevMny_S;
      }
    } else if (obj.O_LevMny_L < obj.O_LevMny_S) {
      if (obj.O_LevMny_L === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = -(obj.O_LevMny_S / obj.O_LevMny_L);
      }
    } else {
      newObj.LevMny_R = 1;
    }

    if (obj.O_OtrRept_L > obj.O_OtrRept_S) {
      if (obj.O_OtrRept_S === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = obj.O_OtrRept_L / obj.O_OtrRept_S;
      }
    } else if (obj.O_OtrRept_L < obj.O_OtrRept_S) {
      if (obj.O_OtrRept_L === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = -(obj.O_OtrRept_S / obj.O_OtrRept_L);
      }
    } else {
      newObj.OtrRept_R = 1;
    }

    if (obj.O_NR_L > obj.O_NR_S) {
      if (obj.O_NR_S === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = obj.O_NR_L / obj.O_NR_S;
      }
    } else if (obj.O_NR_L < obj.O_NR_S) {
      if (obj.O_NR_L === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = -(obj.O_NR_S / obj.O_NR_L);
      }
    } else {
      newObj.NR_R = 1;
    }
    return newObj;
  });

  return fmtLSR(LSR);
}
