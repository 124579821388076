import { createRouter, createWebHashHistory } from "vue-router";
import { storeToRefs } from "pinia";
import useChartData from "@/stores/chartData";

function getRedirectPath(reportType, chartType) {
  if (reportType === "Legacy") {
    return `leg${chartType.toLowerCase()}`;
  } else if (reportType === "DisAgg") {
    return `disagg${chartType.toLowerCase()}`;
  } else if (reportType === "TIFF") {
    return `tiff${chartType.toLowerCase()}`;
  } else if (reportType === "CITS") {
    return `cits${chartType.toLowerCase()}`;
  }
}

function BE(to, from, next) {
  const chartDataStore = useChartData();
  const { reportType, posType, seriesType, valueType, hasCITS, sym } =
    storeToRefs(chartDataStore);
  const { getReptType } = chartDataStore;
  const lastWord = to.fullPath.split("/").pop();
  if (lastWord.startsWith("leg")) {
    reportType.value = "Legacy";
  } else if (lastWord.startsWith("disagg")) {
    if (getReptType(sym.value) == "TIFF") {
      reportType.value = "TIFF";
    } else if (getReptType(sym.value) == "DisAgg") {
      reportType.value = "DisAgg";
    }
  } else if (lastWord.startsWith("tiff")) {
    if (getReptType(sym.value) == "TIFF") {
      reportType.value = "TIFF";
    } else if (getReptType(sym.value) == "DisAgg") {
      reportType.value = "DisAgg";
    }
  } else if (lastWord.startsWith("cits")) {
    if (hasCITS.value) {
      reportType.value = "CITS";
    } else {
      reportType.value = "Legacy";
    }
  }

  if (lastWord.includes("lsrval")) {
    posType.value = "Net";
  } else {
    if (lastWord.includes("net")) {
      posType.value = "Net";
    } else if (lastWord.includes("raw")) {
      posType.value = "Raw";
    }
  }

  if (lastWord.includes("pos")) {
    seriesType.value = "Pos";
  } else if (lastWord.includes("pct")) {
    seriesType.value = "Pct";
  } else if (lastWord.includes("lsr")) {
    seriesType.value = "LSR";
  }

  valueType.value = lastWord.includes("val") ? "Val" : "Idx";

  next();
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: () => {
        return "/home/";
      },
    },
    {
      path: "/home",
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/features",
      component: () => import("../views/Features.vue"),
    },
    {
      path: "/pricing",
      component: () => import("../views/Pricing.vue"),
    },
    {
      path: "/chart",
      redirect: () => {
        const chartDataStore = useChartData();
        const { reportType, chartType } = storeToRefs(chartDataStore);
        return `/chart/${getRedirectPath(reportType.value, chartType.value)}`;
      },
      component: () => import("../views/Chart.vue"),
      children: [
        {
          path: "/chart/legnetposval",
          component: () => import("../views/charts/legNetPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legrawposval",
          component: () => import("../views/charts/legRawPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legnetpctval",
          component: () => import("../views/charts/legNetPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legrawpctval",
          component: () => import("../views/charts/legRawPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legnetposidx",
          component: () => import("../views/charts/legNetPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legrawposidx",
          component: () => import("../views/charts/legRawPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legnetpctidx",
          component: () => import("../views/charts/legNetPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/legrawpctidx",
          component: () => import("../views/charts/legRawPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggnetposval",
          component: () => import("../views/charts/disaggNetPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggrawposval",
          component: () => import("../views/charts/disaggRawPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggnetpctval",
          component: () => import("../views/charts/disaggNetPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggrawpctval",
          component: () => import("../views/charts/disaggRawPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggnetposidx",
          component: () => import("../views/charts/disaggNetPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggrawposidx",
          component: () => import("../views/charts/disaggRawPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggnetpctidx",
          component: () => import("../views/charts/disaggNetPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disaggrawpctidx",
          component: () => import("../views/charts/disaggRawPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffnetposval",
          component: () => import("../views/charts/tiffNetPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffrawposval",
          component: () => import("../views/charts/tiffRawPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffnetpctval",
          component: () => import("../views/charts/tiffNetPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffrawpctval",
          component: () => import("../views/charts/tiffRawPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffnetposidx",
          component: () => import("../views/charts/tiffNetPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffrawposidx",
          component: () => import("../views/charts/tiffRawPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffnetpctidx",
          component: () => import("../views/charts/tiffNetPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tiffrawpctidx",
          component: () => import("../views/charts/tiffRawPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsnetposval",
          component: () => import("../views/charts/citsNetPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsrawposval",
          component: () => import("../views/charts/citsRawPosValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsnetpctval",
          component: () => import("../views/charts/citsNetPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsrawpctval",
          component: () => import("../views/charts/citsRawPctValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsnetposidx",
          component: () => import("../views/charts/citsNetPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsrawposidx",
          component: () => import("../views/charts/citsRawPosIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsnetpctidx",
          component: () => import("../views/charts/citsNetPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citsrawpctidx",
          component: () => import("../views/charts/citsRawPctIdxChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/leglsrval",
          component: () => import("../views/charts/legLSRValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/disagglsrval",
          component: () => import("../views/charts/disaggLSRValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/tifflsrval",
          component: () => import("../views/charts/tiffLSRValChart.vue"),
          beforeEnter: BE,
        },
        {
          path: "/chart/citslsrval",
          component: () => import("../views/charts/citsLSRValChart.vue"),
          beforeEnter: BE,
        },
      ],
    },
  ],
});

let redirected = false;

router.beforeEach((to, from, next) => {
  if (from.path === "/" && !redirected) {
    if (to.path.startsWith("/chart")) {
      redirected = true;
      next("/chart/legnetposval");
    } else {
      redirected = true;
      next();
    }
  } else {
    next();
  }
});
export default router;
