import fmtLSR from "./fmtLSR";
import toDates from "./toDates";

export function toDisAggOptLSRVal(list) {
  let LSR;
  LSR = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (obj.O_Prod_L > obj.O_Prod_S) {
      if (obj.O_Prod_S === 0) {
        newObj.Prod_R = 0;
      } else {
        newObj.Prod_R = obj.O_Prod_L / obj.O_Prod_S;
      }
    } else if (obj.O_Prod_L < obj.O_Prod_S) {
      if (obj.O_Prod_L === 0) {
        newObj.Prod_R = 0;
      } else {
        newObj.Prod_R = -(obj.O_Prod_S / obj.O_Prod_L);
      }
    } else {
      newObj.Prod_R = 1;
    }

    if (obj.O_Swap_L > obj.O_Swap_S) {
      if (obj.O_Swap_S === 0) {
        newObj.Swap_R = 0;
      } else {
        newObj.Swap_R = obj.O_Swap_L / obj.O_Swap_S;
      }
    } else if (obj.O_Swap_L < obj.O_Swap_S) {
      if (obj.O_Swap_L === 0) {
        newObj.Swap_R = 0;
      } else {
        newObj.Swap_R = -(obj.O_Swap_S / obj.O_Swap_L);
      }
    } else {
      newObj.Swap_R = 1;
    }

    if (obj.O_M_Mny_L > obj.O_M_Mny_S) {
      if (obj.O_M_Mny_S === 0) {
        newObj.M_Mny_R = 0;
      } else {
        newObj.M_Mny_R = obj.O_M_Mny_L / obj.O_M_Mny_S;
      }
    } else if (obj.O_M_Mny_L < obj.O_M_Mny_S) {
      if (obj.O_M_Mny_L === 0) {
        newObj.M_Mny_R = 0;
      } else {
        newObj.M_Mny_R = -(obj.O_M_Mny_S / obj.O_M_Mny_L);
      }
    } else {
      newObj.M_Mny_R = 1;
    }

    if (obj.O_OtrRept_L > obj.O_OtrRept_S) {
      if (obj.O_OtrRept_S === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = obj.O_OtrRept_L / obj.O_OtrRept_S;
      }
    } else if (obj.O_OtrRept_L < obj.O_OtrRept_S) {
      if (obj.O_OtrRept_L === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = -(obj.O_OtrRept_S / obj.O_OtrRept_L);
      }
    } else {
      newObj.OtrRept_R = 1;
    }

    if (obj.O_NR_L > obj.O_NR_S) {
      if (obj.O_NR_S === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = obj.O_NR_L / obj.O_NR_S;
      }
    } else if (obj.O_NR_L < obj.O_NR_S) {
      if (obj.O_NR_L === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = -(obj.O_NR_S / obj.O_NR_L);
      }
    } else {
      newObj.NR_R = 1;
    }
    return newObj;
  });

  return fmtLSR(LSR);
}
