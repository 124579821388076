import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toTIFFCmbNetPosVal(list, isContainSprd) {
  const TIFF = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.C_OI;
      newObj.Dlr_Sprd = obj.C_Dlr_Sprd;
      newObj.AssMgr_Sprd = obj.C_AssMgr_Sprd;
      newObj.LevMny_Sprd = obj.C_LevMny_Sprd;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.C_OI -
        obj.C_Dlr_Sprd -
        obj.C_AssMgr_Sprd -
        obj.C_LevMny_Sprd -
        obj.C_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Dlr_N = obj.C_Dlr_L - obj.C_Dlr_S;
    newObj.AssMgr_N = obj.C_AssMgr_L - obj.C_AssMgr_S;
    newObj.LevMny_N = obj.C_LevMny_L - obj.C_LevMny_S;
    newObj.OtrRept_N = obj.C_OtrRept_L - obj.C_OtrRept_S;
    newObj.NR_N = obj.C_NR_L - obj.C_NR_S;
    return newObj;
  });
  return fmtCOT(TIFF);
}
