import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toTIFFCmbRawPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.C_OI;
      newObj.Dlr_Sprd = obj.C_Dlr_Sprd;
      newObj.AssMgr_Sprd = obj.C_AssMgr_Sprd;
      newObj.LevMny_Sprd = obj.C_LevMny_Sprd;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.C_OI -
        obj.C_Dlr_Sprd -
        obj.C_AssMgr_Sprd -
        obj.C_LevMny_Sprd -
        obj.C_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Dlr_L = obj.C_Dlr_L;
    newObj.Dlr_S = -obj.C_Dlr_S;
    newObj.AssMgr_L = obj.C_AssMgr_L;
    newObj.AssMgr_S = -obj.C_AssMgr_S;
    newObj.LevMny_L = obj.C_LevMny_L;
    newObj.LevMny_S = -obj.C_LevMny_S;
    newObj.OtrRept_L = obj.C_OtrRept_L;
    newObj.OtrRept_S = -obj.C_OtrRept_S;
    newObj.NR_L = obj.C_NR_L;
    newObj.NR_S = -obj.C_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
