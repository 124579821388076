import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toDisAggCmbNetPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.C_OI;
      newObj.Swap_Sprd = obj.C_Swap_Sprd;
      newObj.M_Mny_Sprd = obj.C_M_Mny_Sprd;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Prod_N = obj.C_Prod_L - obj.C_Prod_S;
    newObj.Swap_N = obj.C_Swap_L - obj.C_Swap_S;
    newObj.M_Mny_N = obj.C_M_Mny_L - obj.C_M_Mny_S;
    newObj.OtrRept_N = obj.C_OtrRept_L - obj.C_OtrRept_S;
    newObj.NR_N = obj.C_NR_L - obj.C_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
