import CryptoJS from "crypto-js";

const dt1 = "19850215";
const dt2 = "20240413";
// var key = CryptoJS.enc.Utf8.parse("1954682168745975");
// var iv = CryptoJS.enc.Utf8.parse("1954682168745975");

var key = CryptoJS.enc.Utf8.parse(dt1 + dt2);
var iv = CryptoJS.enc.Utf8.parse(dt2 + dt1);

export function dec(decryptStr) {
  const decryptBase64Str = CryptoJS.enc.Base64.parse(decryptStr);
  const createCode = CryptoJS.lib.CipherParams.create({
    ciphertext: decryptBase64Str,
  });
  const decryptedData = CryptoJS.AES.decrypt(createCode, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData).toString();
  return decryptedStr;
}
