import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toLegCmbRawPosVal(list, isContainSprd) {
  const Leg = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.C_OI;
      newObj.NC_Sprd = obj.C_NC_Sprd;
    } else {
      newObj.OI = obj.C_OI - obj.C_NC_Sprd;
      newObj.NC_Sprd = [];
    }
    newObj.CM_L = obj.C_CM_L;
    newObj.CM_S = -obj.C_CM_S;
    newObj.NC_L = obj.C_NC_L;
    newObj.NC_S = -obj.C_NC_S;
    newObj.NR_L = obj.C_NR_L;
    newObj.NR_S = -obj.C_NR_S;
    return newObj;
  });
  return fmtCOT(Leg);
}
