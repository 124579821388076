import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toLegCmbNetPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.C_OI;
      newObj.NC_Sprd = obj.C_NC_Sprd;
    } else {
      newObj.OI = obj.C_OI - obj.C_NC_Sprd;
      newObj.NC_Sprd = [];
    }
    newObj.CM_N = obj.C_CM_L - obj.C_CM_S;
    newObj.NC_N = obj.C_NC_L - obj.C_NC_S;
    newObj.NR_N = obj.C_NR_L - obj.C_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
