import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toLegFutNetPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.F_OI;
      newObj.NC_Sprd = obj.F_NC_Sprd;
    } else {
      newObj.OI = obj.F_OI - obj.F_NC_Sprd;
      newObj.NC_Sprd = [];
    }
    newObj.CM_N = obj.F_CM_L - obj.F_CM_S;
    newObj.NC_N = obj.F_NC_L - obj.F_NC_S;
    newObj.NR_N = obj.F_NR_L - obj.F_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
