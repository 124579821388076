import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toLegFutNetPctVal(list, isContainSprd) {
  let Leg;
  if (isContainSprd) {
    Leg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.CM_N = (obj.F_CM_L - obj.F_CM_S) / obj.F_OI;
      newObj.NC_N = (obj.F_NC_L - obj.F_NC_S) / obj.F_OI;
      newObj.NR_N = (obj.F_NR_L - obj.F_NR_S) / obj.F_OI;
      newObj.NC_Sprd = obj.F_NC_Sprd / obj.F_OI;
      return newObj;
    });
  } else {
    Leg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.CM_N = (obj.F_CM_L - obj.F_CM_S) / (obj.F_OI - obj.F_NC_Sprd);
      newObj.NC_N = (obj.F_NC_L - obj.F_NC_S) / (obj.F_OI - obj.F_NC_Sprd);
      newObj.NR_N = (obj.F_NR_L - obj.F_NR_S) / (obj.F_OI - obj.F_NC_Sprd);
      newObj.NC_Sprd = [];
      return newObj;
    });
  }
  return fmtCOT(Leg);
}
