export default function fmtLSR(rawData) {
  const result = {};
  for (const obj of rawData) {
    for (const [key, value] of Object.entries(obj)) {
      if (!result[key]) {
        result[key] = [];
      }
      if (key === "Dates") {
        result[key].push(value);
      } else {
        result[key].push(parseFloat(value).toFixed(2));
      }
    }
  }
  return result;
}
