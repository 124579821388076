import fmtLSR from "./fmtLSR";
import toDates from "./toDates";

export function toTIFFCmbLSRVal(list) {
  let LSR;
  LSR = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (obj.C_Dlr_L > obj.C_Dlr_S) {
      if (obj.C_Dlr_S === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = obj.C_Dlr_L / obj.C_Dlr_S;
      }
    } else if (obj.C_Dlr_L < obj.C_Dlr_S) {
      if (obj.C_Dlr_L === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = -(obj.C_Dlr_S / obj.C_Dlr_L);
      }
    } else {
      newObj.Dlr_R = 1;
    }

    if (obj.C_AssMgr_L > obj.C_AssMgr_S) {
      if (obj.C_AssMgr_S === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = obj.C_AssMgr_L / obj.C_AssMgr_S;
      }
    } else if (obj.C_AssMgr_L < obj.C_AssMgr_S) {
      if (obj.C_AssMgr_L === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = -(obj.C_AssMgr_S / obj.C_AssMgr_L);
      }
    } else {
      newObj.AssMgr_R = 1;
    }

    if (obj.C_LevMny_L > obj.C_LevMny_S) {
      if (obj.C_LevMny_S === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = obj.C_LevMny_L / obj.C_LevMny_S;
      }
    } else if (obj.C_LevMny_L < obj.C_LevMny_S) {
      if (obj.C_LevMny_L === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = -(obj.C_LevMny_S / obj.C_LevMny_L);
      }
    } else {
      newObj.LevMny_R = 1;
    }

    if (obj.C_OtrRept_L > obj.C_OtrRept_S) {
      if (obj.C_OtrRept_S === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = obj.C_OtrRept_L / obj.C_OtrRept_S;
      }
    } else if (obj.C_OtrRept_L < obj.C_OtrRept_S) {
      if (obj.C_OtrRept_L === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = -(obj.C_OtrRept_S / obj.C_OtrRept_L);
      }
    } else {
      newObj.OtrRept_R = 1;
    }

    if (obj.C_NR_L > obj.C_NR_S) {
      if (obj.C_NR_S === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = obj.C_NR_L / obj.C_NR_S;
      }
    } else if (obj.C_NR_L < obj.C_NR_S) {
      if (obj.C_NR_L === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = -(obj.C_NR_S / obj.C_NR_L);
      }
    } else {
      newObj.NR_R = 1;
    }
    return newObj;
  });

  return fmtLSR(LSR);
}
