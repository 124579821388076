import fmtLSR from "./fmtLSR";
import toDates from "./toDates";

export function toLegOptLSRVal(list) {
  let LSR;
  LSR = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (obj.O_CM_L > obj.O_CM_S) {
      if (obj.O_CM_S === 0) {
        newObj.CM_R = 0;
      } else {
        newObj.CM_R = obj.O_CM_L / obj.O_CM_S;
      }
    } else if (obj.O_CM_L < obj.O_CM_S) {
      if (obj.O_CM_L === 0) {
        newObj.CM_R = 0;
      } else {
        newObj.CM_R = -(obj.O_CM_S / obj.O_CM_L);
      }
    } else {
      newObj.CM_R = 1;
    }

    if (obj.O_NC_L > obj.O_NC_S) {
      if (obj.O_NC_S === 0) {
        newObj.NC_R = 0;
      } else {
        newObj.NC_R = obj.O_NC_L / obj.O_NC_S;
      }
    } else if (obj.O_NC_L < obj.O_NC_S) {
      if (obj.O_NC_L === 0) {
        newObj.NC_R = 0;
      } else {
        newObj.NC_R = -(obj.O_NC_S / obj.O_NC_L);
      }
    } else {
      newObj.NC_R = 1;
    }

    if (obj.O_NR_L > obj.O_NR_S) {
      if (obj.O_NR_S === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = obj.O_NR_L / obj.O_NR_S;
      }
    } else if (obj.O_NR_L < obj.O_NR_S) {
      if (obj.O_NR_L === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = -(obj.O_NR_S / obj.O_NR_L);
      }
    } else {
      newObj.NR_R = 1;
    }
    return newObj;
  });
  return fmtLSR(LSR);
}
