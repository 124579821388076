import toIdx from "./toIdx";

export default function fmtIdx(rawData, period) {
  const result = {};
  const index = {};

  for (const obj of rawData) {
    for (const [key, value] of Object.entries(obj)) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
    }
  }

  index.Dates = result.Dates.slice(-result.Dates.length + period - 1);

  for (let key in result) {
    if (key !== "Dates") {
      index[key] = toIdx(result[key], period);
    }
  }

  return index;
}
