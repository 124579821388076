export default function toIdx(arr, period) {
  const idxArr = [];

  for (let i = arr.length - 1; i >= 0; i--) {
    const countBack = Math.min(i + 1, period);

    if (countBack < period) {
      break;
    }

    const subArr = arr.slice(i - countBack + 1, i + 1);

    const min = Math.min(...subArr);
    const max = Math.max(...subArr);

    const value = arr[i];
    const fraction = (value - min) / (max - min);
    idxArr.push(fraction);
  }

  for (let i = 0; i < idxArr.length; i++) {
    if (isNaN(idxArr[i])) {
      idxArr[i] = [];
    }
  }

  return idxArr.reverse().map((x) => (x * 100).toFixed(2));
}
