import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toCITNetPctIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.NC_N = (obj.S_NC_L - obj.S_NC_S) / obj.S_OI;
      newObj.NC_Sprd = obj.S_NC_Sprd / obj.S_OI;
      newObj.CM_N = (obj.S_CM_L - obj.S_CM_S) / obj.S_OI;
      newObj.NR_N = (obj.S_NR_L - obj.S_NR_S) / obj.S_OI;
      newObj.CIT_N = (obj.S_CIT_L - obj.S_CIT_S) / obj.S_OI;
    } else {
      newObj.NC_N = (obj.S_NC_L - obj.S_NC_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.NC_Sprd = [];
      newObj.CM_N = (obj.S_CM_L - obj.S_CM_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.NR_N = (obj.S_NR_L - obj.S_NR_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.CIT_N = (obj.S_CIT_L - obj.S_CIT_S) / (obj.S_OI - obj.S_NC_Sprd);
    }
    return newObj;
  });
  return fmtIdx(Idx, period);
}
