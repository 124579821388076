import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toDisAggFutRawPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.F_OI;
      newObj.Swap_Sprd = obj.F_Swap_Sprd;
      newObj.M_Mny_Sprd = obj.F_M_Mny_Sprd;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Prod_L = obj.F_Prod_L;
    newObj.Prod_S = -obj.F_Prod_S;
    newObj.Swap_L = obj.F_Swap_L;
    newObj.Swap_S = -obj.F_Swap_S;
    newObj.M_Mny_L = obj.F_M_Mny_L;
    newObj.M_Mny_S = -obj.F_M_Mny_S;
    newObj.OtrRept_L = obj.F_OtrRept_L;
    newObj.OtrRept_S = -obj.F_OtrRept_S;
    newObj.NR_L = obj.F_NR_L;
    newObj.NR_S = -obj.F_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
