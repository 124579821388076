<template>
  <router-view></router-view>
</template>

<script setup>
import autofit from "autofit.js";
import { onMounted } from "vue";

onMounted(() => {
  autofit.init({
    dw: 1920,
    dh: 928,
    el: "#app",
    resize: true,
  });
});

const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 1);
    super(callback);
  }
};
</script>

<style scoped lang="less"></style>
