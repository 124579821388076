import { fmtVolLabel } from "./fmtVolLabel";
import { storeToRefs } from "pinia";
import useChartData from "@/stores/chartData";

function fmtTooltipDate(rawDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [month, day, year] = rawDate.split("-");
  const paddedDay = day.padStart(2, "0");
  const formattedDate = `${
    months[parseInt(month, 10) - 1]
  } ${paddedDay}, ${year}`;

  return formattedDate;
}

export function fmtTooltip(params) {
  const chartDataStore = useChartData();
  const { date, open, high, low, close, volume, seriesType, valueType } =
    storeToRefs(chartDataStore);
  const VPs = params.find((element) => element.seriesIndex === 0);
  date.value = fmtTooltipDate(VPs.name);
  open.value = VPs.data[1];
  high.value = VPs.data[4];
  low.value = VPs.data[3];
  close.value = VPs.data[2];
  volume.value = fmtVolLabel(VPs.data[5]);
  if (seriesType.value === "Pos") {
    let COT;
    if (valueType.value === "Idx") {
      COT = params.filter((element) => element.seriesIndex >= 3);
      const tooltipContent = COT.map(
        (element) =>
          `${element.marker}${element.seriesName}: ${element.value}<br />`
      );
      tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
      return tooltipContent.join("");
    }

    const OISelected = params.some((element) => element.seriesIndex === 4);
    if (!OISelected) {
      const OI_On_Vol = params.find((element) => element.seriesIndex === 3);
      if (OI_On_Vol) {
        OI_On_Vol.seriesName = "Open Interest";
      }
      COT = params.filter((element) => element.seriesIndex >= 3);
    } else {
      COT = params.filter((element) => element.seriesIndex > 3);
    }
    COT = COT.sort((a, b) => a.seriesIndex - b.seriesIndex);
    const tooltipContent = COT.map(
      (element) =>
        `${element.marker}${element.seriesName}: ${element.value}<br />`
    );
    tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
    return tooltipContent.join("");
  } else if (seriesType.value === "Pct") {
    let COT;
    if (valueType.value === "Idx") {
      COT = params.filter((element) => element.seriesIndex >= 3);
      const tooltipContent = COT.map(
        (element) =>
          `${element.marker}${element.seriesName}: ${element.value}<br />`
      );
      tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
      return tooltipContent.join("");
    }
    COT = params.filter((element) => element.seriesIndex >= 3);
    const tooltipContent = COT.map(
      (element) =>
        `${element.marker}${element.seriesName}: ${(
          element.value * 100
        ).toFixed(2)}%<br />`
    );
    tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
    return tooltipContent.join("");
  } else if (seriesType.value === "LSR") {
    let COT;
    if (valueType.value === "Idx") {
      COT = params.filter((element) => element.seriesIndex >= 3);
      const tooltipContent = COT.map(
        (element) =>
          `${element.marker}${element.seriesName}: ${element.value}<br />`
      );
      tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
      return tooltipContent.join("");
    }
    COT = params.filter((element) => element.seriesIndex >= 3);
    const tooltipContent = COT.map(
      (element) =>
        `${element.marker}${element.seriesName}: ${element.value}<br />`
    );
    tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
    return tooltipContent.join("");
  }
}

// export function fmtTooltipLegNetPos(params) {
//   const chartDataStore = useChartData();
//   const { date, open, high, low, close, volume } = storeToRefs(chartDataStore);
//   const VPs = params.find((element) => element.seriesIndex === 0);
//   date.value = fmtTooltipDate(VPs.name);
//   open.value = VPs.data[1];
//   high.value = VPs.data[4];
//   low.value = VPs.data[3];
//   close.value = VPs.data[2];
//   volume.value = fmtVolLabel(VPs.data[5]);
//   const OISelected = params.some((element) => element.seriesIndex === 4);
//   let COT;
//   if (!OISelected) {
//     const OI_On_Vol = params.find((element) => element.seriesIndex === 3);
//     if (OI_On_Vol) {
//       OI_On_Vol.seriesName = "OI";
//     }
//     COT = params.filter((element) => element.seriesIndex >= 3);
//   } else {
//     COT = params.filter((element) => element.seriesIndex > 3);
//   }
//   const tooltipContent = COT.map(
//     (element) => element.seriesName + ": " + element.value + "<br />"
//   );
//   tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
//   return tooltipContent.join("");
// }

// export function fmtTooltipLegNetPosIdx(params) {
//   const chartDataStore = useChartData();
//   const { date, open, high, low, close, volume } = storeToRefs(chartDataStore);
//   const VPs = params.find((element) => element.seriesIndex === 0);
//   date.value = fmtTooltipDate(VPs.name);
//   open.value = VPs.data[1];
//   high.value = VPs.data[4];
//   low.value = VPs.data[3];
//   close.value = VPs.data[2];
//   volume.value = fmtVolLabel(VPs.data[5]);
//
//   const OISelected = params.some((element) => element.seriesIndex === 4);
//   let COT;
//   if (!OISelected) {
//     const OI_On_Vol = params.find((element) => element.seriesIndex === 2);
//     if (OI_On_Vol) {
//       OI_On_Vol.seriesName = "OI";
//     }
//     COT = params.filter((element) => element.seriesIndex >= 2);
//   } else {
//     COT = params.filter((element) => element.seriesIndex > 2);
//   }
//   const tooltipContent = COT.map(
//     (element) => element.seriesName + ": " + element.value + "<br />"
//   );
//   tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
//   return tooltipContent.join("");
// }

// export function fmtTooltipLegNetRat(params) {
//   const chartDataStore = useChartData();
//   const { date, open, high, low, close, volume } = storeToRefs(chartDataStore);
//   const VPs = params.find((element) => element.seriesIndex === 0);
//   date.value = fmtTooltipDate(VPs.name);
//   open.value = VPs.data[1];
//   high.value = VPs.data[4];
//   low.value = VPs.data[3];
//   close.value = VPs.data[2];
//   volume.value = fmtVolLabel(VPs.data[5]);
//   let COT;
//   COT = params.filter((element) => element.seriesIndex >= 3);
//   const tooltipContent = COT.map(
//     (element) =>
//       element.seriesName + ": " + (element.value * 100).toFixed(2) + "%<br />"
//   );
//   tooltipContent.unshift("Date: " + fmtTooltipDate(VPs.name) + "<br/>");
//   return tooltipContent.join("");
// }
