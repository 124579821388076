import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toCITNetPosVal(list, isContainSprd) {
  let CIT;
  if (isContainSprd) {
    CIT = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.OI = obj.S_OI;
      newObj.NC_N = obj.S_NC_L - obj.S_NC_S;
      newObj.NC_Sprd = obj.S_NC_Sprd;
      newObj.CM_N = obj.S_CM_L - obj.S_CM_S;
      newObj.NR_N = obj.S_NR_L - obj.S_NR_S;
      newObj.CIT_N = obj.S_CIT_L - obj.S_CIT_S;
      return newObj;
    });
  } else {
    CIT = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.OI = obj.S_OI - obj.S_NC_Sprd;
      newObj.NC_N = obj.S_NC_L - obj.S_NC_S;
      newObj.NC_Sprd = [];
      newObj.CM_N = obj.S_CM_L - obj.S_CM_S;
      newObj.NR_N = obj.S_NR_L - obj.S_NR_S;
      newObj.CIT_N = obj.S_CIT_L - obj.S_CIT_S;
      return newObj;
    });
  }
  return fmtCOT(CIT);
}
