import { defineStore } from "pinia";
import { ref } from "vue";
import {
  dec,
  toVPs,
  fmtVolLabel,
  toCITNetPctVal,
  toCITNetPosVal,
  toCITRawPctVal,
  toCITRawPosVal,
  toCITNetPctIdx,
  toCITNetPosIdx,
  toCITRawPctIdx,
  toCITRawPosIdx,
  toLegFutNetPctVal,
  toLegFutNetPosVal,
  toLegFutRawPctVal,
  toLegFutRawPosVal,
  toLegCmbNetPctVal,
  toLegCmbNetPosVal,
  toLegCmbRawPctVal,
  toLegCmbRawPosVal,
  toLegFutNetPosIdx,
  toLegFutNetPctIdx,
  toLegFutRawPosIdx,
  toLegFutRawPctIdx,
  toLegCmbRawPosIdx,
  toLegCmbRawPctIdx,
  toLegCmbNetPosIdx,
  toLegCmbNetPctIdx,
  toTIFFFutNetPctVal,
  toTIFFFutNetPosVal,
  toTIFFFutRawPctVal,
  toTIFFFutRawPosVal,
  toTIFFCmbNetPctVal,
  toTIFFCmbNetPosVal,
  toTIFFCmbRawPctVal,
  toTIFFCmbRawPosVal,
  toTIFFFutNetPctIdx,
  toTIFFFutNetPosIdx,
  toTIFFFutRawPctIdx,
  toTIFFFutRawPosIdx,
  toTIFFCmbNetPctIdx,
  toTIFFCmbNetPosIdx,
  toTIFFCmbRawPctIdx,
  toTIFFCmbRawPosIdx,
  toDisAggFutNetPctVal,
  toDisAggFutNetPosVal,
  toDisAggFutRawPctVal,
  toDisAggFutRawPosVal,
  toDisAggCmbNetPctVal,
  toDisAggCmbNetPosVal,
  toDisAggCmbRawPctVal,
  toDisAggCmbRawPosVal,
  toDisAggFutNetPctIdx,
  toDisAggFutNetPosIdx,
  toDisAggFutRawPctIdx,
  toDisAggFutRawPosIdx,
  toDisAggCmbNetPctIdx,
  toDisAggCmbNetPosIdx,
  toDisAggCmbRawPctIdx,
  toDisAggCmbRawPosIdx,
  toLegFutLSRVal,
  toLegCmbLSRVal,
  toLegOptLSRVal,
  toDisAggFutLSRVal,
  toDisAggCmbLSRVal,
  toDisAggOptLSRVal,
  toTIFFFutLSRVal,
  toTIFFCmbLSRVal,
  toTIFFOptLSRVal,
  toCITLSRVal,
} from "@/utils";

import { getVPs, getSFs, getSCs, getLFs, getLCs, getCIs } from "@/service";
import allCategories from "@/assets/data/allCategories";

const useChartData = defineStore("chartData", () => {
  const sym = ref("GC");
  const symbol = ref("Gold");
  const longReptType = ref("DisAgg");
  const prevReptType = ref("Legacy");
  const reportType = ref("Legacy");
  const posType = ref("Net");
  const seriesType = ref("Pos");
  const valueType = ref("Val");
  const dataType = ref("Futures");
  const chartType = ref("NetPosVal");
  const hasCITS = ref(false);
  const zoomStart = ref(0);
  const zoomEnd = ref(100);
  const rangeTab = ref("5y");
  const indexTab = ref("3y");
  const rangeRst = ref(false);
  const indexRst = ref(false);
  const VPs = ref([]);
  const COT = ref([]);
  const resF = ref([]);
  const resC = ref([]);
  const resS = ref([]);
  const resV = ref([]);
  const date = ref("");
  const open = ref(0);
  const high = ref(0);
  const low = ref(0);
  const close = ref(0);
  const volume = ref(0);
  const COTLength = ref(0);
  const rawLength = ref(0);
  const indexPeriod = ref(156);
  const isContainSprd = ref(true);
  const isLoading = ref(true);

  const legNetPosValLegend = ref({
    "OI On Volume": false,
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const legRawPosValLegend = ref({
    "OI On Volume": false,
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const legNetPctValLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const legRawPctValLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const legNetPosIdxLegend = ref({
    "Open Interest": false,
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const legRawPosIdxLegend = ref({
    "Open Interest": false,
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const legNetPctIdxLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const legRawPctIdxLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const disaggNetPosValLegend = ref({
    "OI On Volume": false,
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const disaggRawPosValLegend = ref({
    "OI On Volume": false,
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const disaggNetPctValLegend = ref({
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const disaggRawPctValLegend = ref({
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const disaggNetPosIdxLegend = ref({
    "Open Interest": false,
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const disaggRawPosIdxLegend = ref({
    "Open Interest": false,
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const disaggNetPctIdxLegend = ref({
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const disaggRawPctIdxLegend = ref({
    "Swap Dealers Spreads": false,
    "Managed Money Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const tiffNetPosValLegend = ref({
    "OI On Volume": false,
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const tiffRawPosValLegend = ref({
    "OI On Volume": false,
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const tiffNetPctValLegend = ref({
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const tiffRawPctValLegend = ref({
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const tiffNetPosIdxLegend = ref({
    "Open Interest": false,
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const tiffRawPosIdxLegend = ref({
    "Open Interest": false,
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const tiffNetPctIdxLegend = ref({
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable": false,
  });
  const tiffRawPctIdxLegend = ref({
    "Dealer Intermediary Spreads": false,
    "Leveraged Funds Spreads": false,
    "Asset Manager/Institutional Spreads": false,
    "Other Reportables Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const citsNetPosValLegend = ref({
    "OI On Volume": false,
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const citsRawPosValLegend = ref({
    "OI On Volume": false,
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const citsNetPctValLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const citsRawPctValLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const citsNetPosIdxLegend = ref({
    "Open Interest": false,
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const citsRawPosIdxLegend = ref({
    "Open Interest": false,
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const citsNetPctIdxLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable": false,
  });
  const citsRawPctIdxLegend = ref({
    "Non-commercial Spreads": false,
    "Non-reportable Long": false,
    "Non-reportable Short": false,
  });
  const legLSRValLegend = ref({
    "Non-reportable": false,
  });
  const disaggLSRValLegend = ref({
    "Non-reportable": false,
  });
  const tiffLSRValLegend = ref({
    "Non-reportable": false,
  });
  const citsLSRValLegend = ref({
    "Non-reportable": false,
  });

  async function init() {
    isLoading.value = true;
    handleReptType("GC");
    await reqCOT("GC");
    await genCOT();
    await reqVPs("GC");
    await genVPs(COTLength.value);
    rstRangeTab();
    isLoading.value = false;
  }

  async function refAll(ticker) {
    isLoading.value = true;
    handleReptType(ticker);
    await reqCOT(ticker);
    await genCOT();
    await reqVPs(ticker);
    await genVPs(COTLength.value);
    isLoading.value = false;
  }

  async function refCOT(ticker) {
    isLoading.value = true;
    await reqCOT(ticker);
    await genCOT();
    await genVPs(COTLength.value);
    isLoading.value = false;
  }
  async function updCOT() {
    isLoading.value = true;
    await genCOT();
    await genVPs(COTLength.value);
    isLoading.value = false;
  }

  async function reqVPs(ticker) {
    isLoading.value = true;
    resV.value = await getVPs(ticker);
    resV.value = JSON.parse(dec(resV.value));
    isLoading.value = false;
  }

  async function genVPs(offset) {
    VPs.value = toVPs(resV.value, offset);
    date.value = fmtDate(VPs.value.Dates[VPs.value.Dates.length - 1]);
    open.value = VPs.value.Prices[VPs.value.Prices.length - 1][0];
    high.value = VPs.value.Prices[VPs.value.Prices.length - 1][3];
    low.value = VPs.value.Prices[VPs.value.Prices.length - 1][2];
    close.value = VPs.value.Prices[VPs.value.Prices.length - 1][1];
    volume.value = fmtVolLabel(
      VPs.value.Volumes[VPs.value.Volumes.length - 1][1]
    );
  }

  async function reqCOT(ticker) {
    isLoading.value = true;
    if (reportType.value === "Legacy") {
      if (dataType.value === "Futures") {
        resF.value = await getSFs(ticker);
        resF.value = JSON.parse(dec(resF.value));
        rawLength.value = resF.value.length;
      } else if (dataType.value === "Combined") {
        resC.value = await getSCs(ticker);
        resC.value = JSON.parse(dec(resC.value));
        rawLength.value = resC.value.length;
      } else if (dataType.value === "Options") {
        resF.value = await getSFs(ticker);
        resF.value = JSON.parse(dec(resF.value));
        resC.value = await getSCs(ticker);
        resC.value = JSON.parse(dec(resC.value));
        rawLength.value = resC.value.length;
      }
    } else if (reportType.value === "DisAgg" || reportType.value === "TIFF") {
      if (dataType.value === "Futures") {
        resF.value = await getLFs(ticker);
        resF.value = JSON.parse(dec(resF.value));
        rawLength.value = resF.value.length;
      } else if (dataType.value === "Combined") {
        resC.value = await getLCs(ticker);
        resC.value = JSON.parse(dec(resC.value));
        rawLength.value = resC.value.length;
      } else if (dataType.value === "Options") {
        resF.value = await getLFs(ticker);
        resF.value = JSON.parse(dec(resF.value));
        resC.value = await getLCs(ticker);
        resC.value = JSON.parse(dec(resC.value));
        rawLength.value = resC.value.length;
      }
    } else if (reportType.value === "CITS") {
      resS.value = await getCIs(ticker);
      resS.value = JSON.parse(dec(resS.value));
      rawLength.value = resS.value.length;
    }
    isLoading.value = false;
  }

  async function genCOT() {
    if (chartType.value === "NetPosVal") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutNetPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbNetPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toLegFutNetPosVal(resF.value, isContainSprd.value);
          const C = toLegCmbNetPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutNetPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbNetPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toDisAggFutNetPosVal(resF.value, isContainSprd.value);
          const C = toDisAggCmbNetPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutNetPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbNetPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toTIFFFutNetPosVal(resF.value, isContainSprd.value);
          const C = toTIFFCmbNetPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITNetPosVal(resS.value, isContainSprd.value);
      }
    } else if (chartType.value === "RawPosVal") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutRawPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbRawPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toLegFutRawPosVal(resF.value, isContainSprd.value);
          const C = toLegCmbRawPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutRawPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbRawPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toDisAggFutRawPosVal(resF.value, isContainSprd.value);
          const C = toDisAggCmbRawPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutRawPosVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbRawPosVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          const F = toTIFFFutRawPosVal(resF.value, isContainSprd.value);
          const C = toTIFFCmbRawPosVal(resC.value, isContainSprd.value);
          COT.value = calcOptPos(C, F);
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITRawPosVal(resS.value, isContainSprd.value);
      }
    } else if (chartType.value === "NetPctVal") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutNetPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbNetPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbNetPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutNetPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbNetPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbNetPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutNetPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbNetPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbNetPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITNetPctVal(resS.value, isContainSprd.value);
      }
    } else if (chartType.value === "RawPctVal") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutRawPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbRawPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbRawPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutRawPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbRawPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbRawPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutRawPctVal(resF.value, isContainSprd.value);
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbRawPctVal(resC.value, isContainSprd.value);
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbRawPctVal(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITRawPctVal(resS.value, isContainSprd.value);
      }
    } else if (chartType.value === "NetPosIdx") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutNetPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbNetPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbNetPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutNetPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbNetPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbNetPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutNetPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbNetPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbNetPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITNetPosIdx(
          resS.value,
          isContainSprd.value,
          indexPeriod.value
        );
      }
    } else if (chartType.value === "NetPctIdx") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutNetPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbNetPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbNetPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutNetPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbNetPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbNetPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutNetPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbNetPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbNetPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITNetPctIdx(
          resS.value,
          isContainSprd.value,
          indexPeriod.value
        );
      }
    } else if (chartType.value === "RawPosIdx") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutRawPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbRawPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbRawPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutRawPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbRawPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbRawPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutRawPosIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbRawPosIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbRawPosIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITRawPosIdx(
          resS.value,
          isContainSprd.value,
          indexPeriod.value
        );
      }
    } else if (chartType.value === "RawPctIdx") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutRawPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbRawPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toLegCmbRawPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutRawPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbRawPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toDisAggCmbRawPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutRawPctIdx(
            resF.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbRawPctIdx(
            resC.value,
            isContainSprd.value,
            indexPeriod.value
          );
        } else if (dataType.value === "Options") {
          COT.value = toTIFFCmbRawPctIdx(
            calcOptIdx(resC.value, resF.value),
            isContainSprd.value,
            indexPeriod.value
          );
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITRawPctIdx(
          resS.value,
          isContainSprd.value,
          indexPeriod.value
        );
      }
    } else if (chartType.value === "LSRVal") {
      if (reportType.value === "Legacy") {
        if (dataType.value === "Futures") {
          COT.value = toLegFutLSRVal(resF.value);
        } else if (dataType.value === "Combined") {
          COT.value = toLegCmbLSRVal(resC.value);
        } else if (dataType.value === "Options") {
          COT.value = toLegOptLSRVal(calcRawOpts(resC.value, resF.value));
        }
      } else if (reportType.value === "DisAgg") {
        if (dataType.value === "Futures") {
          COT.value = toDisAggFutLSRVal(resF.value);
        } else if (dataType.value === "Combined") {
          COT.value = toDisAggCmbLSRVal(resC.value);
        } else if (dataType.value === "Options") {
          COT.value = toDisAggOptLSRVal(calcRawOpts(resC.value, resF.value));
        }
      } else if (reportType.value === "TIFF") {
        if (dataType.value === "Futures") {
          COT.value = toTIFFFutLSRVal(resF.value);
        } else if (dataType.value === "Combined") {
          COT.value = toTIFFCmbLSRVal(resC.value);
        } else if (dataType.value === "Options") {
          COT.value = toTIFFOptLSRVal(calcRawOpts(resC.value, resF.value));
        }
      } else if (reportType.value === "CITS") {
        COT.value = toCITLSRVal(resS.value);
      }
    }
    calcParams();
  }

  function calcParams() {
    COTLength.value = COT.value.Dates.length;
  }

  function rstZoomStart(rangeVal) {
    rangeRst.value = false;
    if (rangeVal === "all") {
      zoomStart.value = 0;
    } else {
      zoomStart.value = ((COTLength.value - rangeVal) * 100) / COTLength.value;
    }
    zoomEnd.value = 100;
  }

  function rstRangeTab() {
    if (rangeTab.value === "all") {
      rangeTab.value = "all";
      rstZoomStart("all");
    } else if (rangeTab.value === "20y") {
      if (COTLength.value >= 1040) {
        rangeTab.value = "20y";
        rstZoomStart(1040);
      } else if (COTLength.value >= 520) {
        rangeTab.value = "10y";
        rstZoomStart(520);
      } else if (COTLength.value >= 260) {
        rangeTab.value = "5y";
        rstZoomStart(260);
      } else if (COTLength.value >= 156) {
        rangeTab.value = "3y";
        rstZoomStart(156);
      } else if (COTLength.value >= 104) {
        rangeTab.value = "2y";
        rstZoomStart(104);
      } else if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else if (COTLength.value < 52) {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    } else if (rangeTab.value === "10y") {
      if (COTLength.value >= 520) {
        rangeTab.value = "10y";
        rstZoomStart(520);
      } else if (COTLength.value >= 260) {
        rangeTab.value = "5y";
        rstZoomStart(260);
      } else if (COTLength.value >= 156) {
        rangeTab.value = "3y";
        rstZoomStart(156);
      } else if (COTLength.value >= 104) {
        rangeTab.value = "2y";
        rstZoomStart(104);
      } else if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else if (COTLength.value < 52) {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    } else if (rangeTab.value === "5y") {
      if (COTLength.value >= 260) {
        rangeTab.value = "5y";
        rstZoomStart(260);
      } else if (COTLength.value >= 156) {
        rangeTab.value = "3y";
        rstZoomStart(156);
      } else if (COTLength.value >= 104) {
        rangeTab.value = "2y";
        rstZoomStart(104);
      } else if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else if (COTLength.value < 52) {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    } else if (rangeTab.value === "3y") {
      if (COTLength.value >= 156) {
        rangeTab.value = "3y";
        rstZoomStart(156);
      } else if (COTLength.value >= 104) {
        rangeTab.value = "2y";
        rstZoomStart(104);
      } else if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else if (COTLength.value < 52) {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    } else if (rangeTab.value === "2y") {
      if (COTLength.value >= 104) {
        rangeTab.value = "2y";
        rstZoomStart(104);
      } else if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else if (COTLength.value < 52) {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    } else if (rangeTab.value === "1y") {
      if (COTLength.value >= 52) {
        rangeTab.value = "1y";
        rstZoomStart(52);
      } else {
        rangeTab.value = "all";
        rstZoomStart("all");
      }
    }
  }

  function rstIndexTab() {
    if (indexTab.value === "max") {
      indexPeriod.value = rawLength.value - 51;
    } else if (indexTab.value === "10y") {
      if (rawLength.value - 51 >= 520) {
        indexPeriod.value = 520;
      } else if (rawLength.value - 51 >= 260) {
        indexPeriod.value = 260;
        indexTab.value = "5y";
      } else if (rawLength.value - 51 >= 156) {
        indexPeriod.value = 156;
        indexTab.value = "3y";
      } else if (rawLength.value - 51 >= 104) {
        indexPeriod.value = 104;
        indexTab.value = "2y";
      } else if (rawLength.value - 51 >= 52) {
        indexPeriod.value = 52;
        indexTab.value = "1y";
      } else if (rawLength.value - 51 >= 26) {
        indexPeriod.value = 26;
        indexTab.value = "6m";
      }
    } else if (indexTab.value === "5y") {
      if (rawLength.value - 51 >= 260) {
        indexPeriod.value = 260;
      } else if (rawLength.value - 51 >= 156) {
        indexPeriod.value = 156;
        indexTab.value = "3y";
      } else if (rawLength.value - 51 >= 104) {
        indexPeriod.value = 104;
        indexTab.value = "2y";
      } else if (rawLength.value - 51 >= 52) {
        indexPeriod.value = 52;
        indexTab.value = "1y";
      } else if (rawLength.value - 51 >= 26) {
        indexPeriod.value = 26;
        indexTab.value = "6m";
      }
    } else if (indexTab.value === "3y") {
      if (rawLength.value - 51 >= 156) {
        indexPeriod.value = 156;
      } else if (rawLength.value - 51 >= 104) {
        indexPeriod.value = 104;
        indexTab.value = "2y";
      } else if (rawLength.value - 51 >= 52) {
        indexPeriod.value = 52;
        indexTab.value = "1y";
      } else if (rawLength.value - 51 >= 26) {
        indexPeriod.value = 26;
        indexTab.value = "6m";
      }
    } else if (indexTab.value === "2y") {
      if (rawLength.value - 51 >= 104) {
        indexPeriod.value = 104;
      } else if (rawLength.value - 51 >= 52) {
        indexPeriod.value = 52;
        indexTab.value = "1y";
      } else if (rawLength.value - 51 >= 26) {
        indexPeriod.value = 26;
        indexTab.value = "6m";
      }
    } else if (indexTab.value === "1y") {
      if (rawLength.value - 51 >= 52) {
        indexPeriod.value = 52;
      } else if (rawLength.value - 51 >= 26) {
        indexPeriod.value = 26;
        indexTab.value = "6m";
      }
    } else if (indexTab.value === "6m") {
      indexPeriod.value = 26;
    }
  }

  function checkIndexTab() {
    if (
      ![rawLength.value - 51, 520, 260, 156, 104, 52, 26].includes(
        indexPeriod.value
      )
    ) {
      indexRst.value = true;
    } else {
      indexRst.value = false;
      if (indexPeriod.value === rawLength.value - 51) {
        indexTab.value = "max";
      } else if (indexPeriod.value === 520) {
        indexTab.value = "10y";
      } else if (indexPeriod.value === 260) {
        indexTab.value = "5y";
      } else if (indexPeriod.value === 156) {
        indexTab.value = "3y";
      } else if (indexPeriod.value === 104) {
        indexTab.value = "2y";
      } else if (indexPeriod.value === 52) {
        indexTab.value = "1y";
      } else if (indexPeriod.value === 26) {
        indexTab.value = "6m";
      }
    }
  }

  function calcOptIdx(C, F) {
    if (C.length !== F.length) {
      throw new Error("The length of the arrays C and F must be equal.");
    }

    const result = [];

    for (let i = 0; i < C.length; i++) {
      const cObj = C[i];
      const fObj = F[i];
      const newObj = { Date: cObj.Date };

      for (const key in cObj) {
        if (key !== "Date") {
          const cValue = cObj[key];
          const fKey = "F" + key.slice(1);
          const fValue = fObj[fKey];
          newObj[key] = cValue - fValue;
        }
      }

      result.push(newObj);
    }

    return result;
  }

  function calcOptPos(combined, futures) {
    const result = {};
    for (const key in combined) {
      if (combined.hasOwnProperty(key) && futures.hasOwnProperty(key)) {
        if (key === "Dates") {
          result[key] = combined[key];
          continue;
        }
        const arrayA = combined[key];
        const arrayB = futures[key];
        const differenceArray = arrayA.map((elementA, index) => {
          return elementA - arrayB[index];
        });
        result[key] = differenceArray;
      } else if (combined.hasOwnProperty(key)) {
        result[key] = combined[key];
      }
    }
    return result;
  }

  function calcRawOpts(array1, array2) {
    let newArray = [];

    for (let i = 0; i < array1.length; i++) {
      let obj = { Date: array1[i].Date };

      for (let key in array1[i]) {
        if (key.startsWith("C_")) {
          let correspondingKey = key.replace("C_", "F_");
          obj[key.replace("C_", "O_")] =
            array1[i][key] - array2[i][correspondingKey];
        }
      }

      newArray.push(obj);
    }

    return newArray;
  }

  function fmtDate(dateString) {
    const parts = dateString.split("-");
    const month = getMonthName(parseInt(parts[0], 10));
    const day = ("0" + parseInt(parts[1], 10)).slice(-2);
    const year = parts[2];
    return month + " " + day + ", " + year;
  }

  function getMonthName(monthIndex) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthIndex - 1];
  }
  function getSymbol(ticker) {
    const category = allCategories.find((s) => s.ticker === ticker);
    return category ? category.symbol : null;
  }

  function getReptType(ticker) {
    const category = allCategories.find((s) => s.ticker === ticker);
    return category ? category.type : null;
  }

  function getCITS(ticker) {
    const category = allCategories.find((c) => c.ticker === ticker);
    return !!(category && category.CITS);
  }

  function handleReptType(ticker) {
    longReptType.value = getReptType(ticker);
    symbol.value = getSymbol(ticker);
    hasCITS.value = getCITS(ticker);
    if (reportType.value != "CITS") {
      if (reportType.value != "Legacy") {
        reportType.value = getReptType(ticker);
      }
      prevReptType.value = reportType.value;
    } else {
      if (!hasCITS.value) {
        if (prevReptType.value === "TIFF" || prevReptType.value === "DisAgg") {
          reportType.value = getReptType(ticker);
        } else {
          reportType.value = "Legacy";
        }
      }
    }
  }

  function setChartType() {
    if (seriesType.value === "LSR") {
      chartType.value = seriesType.value + valueType.value;
    } else {
      chartType.value = posType.value + seriesType.value + valueType.value;
    }
  }

  // function prepareDataForExport() {
  //   const combinedData = VPs.value.Dates.map((date, index) => ({
  //     Dates: date.replace(/-/g, "/"),
  //     Open: VPs.value.Prices[index][0],
  //     High: VPs.value.Prices[index][3],
  //     Low: VPs.value.Prices[index][2],
  //     Close: VPs.value.Prices[index][1],
  //     Volume: VPs.value.Prices[index][4],
  //     ...Object.keys(COT.value).reduce((acc, key) => {
  //       if (key !== "Dates") {
  //         acc[key] = COT.value[key][index];
  //       }
  //       return acc;
  //     }, {}),
  //   }));
  //
  //   return combinedData;
  // }

  function prepareDataForExport() {
    const sortedKeyList = [
      "OI",
      "CM_N",
      "CM_L",
      "CM_S",
      "CM_R",
      "NC_N",
      "NC_L",
      "NC_S",
      "NC_R",
      "NC_Sprd",
      "CIT_N",
      "CIT_L",
      "CIT_S",
      "CIT_R",
      "Prod_N",
      "Prod_L",
      "Prod_S",
      "Prod_R",
      "Swap_N",
      "Swap_L",
      "Swap_S",
      "Swap_R",
      "Swap_Sprd",
      "M_Mny_N",
      "M_Mny_L",
      "M_Mny_S",
      "M_Mny_R",
      "M_Mny_Sprd",
      "Dlr_N",
      "Dlr_L",
      "Dlr_S",
      "Dlr_R",
      "Dlr_Sprd",
      "AssMgr_N",
      "AssMgr_L",
      "AssMgr_S",
      "AssMgr_R",
      "AssMgr_Sprd",
      "LevMny_N",
      "LevMny_L",
      "LevMny_S",
      "LevMny_R",
      "LevMny_Sprd",
      "OtrRept_N",
      "OtrRept_L",
      "OtrRept_S",
      "OtrRept_R",
      "OtrRept_Sprd",
      "NR_N",
      "NR_L",
      "NR_S",
      "NR_R",
    ];

    const combinedData = VPs.value.Dates.map((date, index) => ({
      Dates: date.replace(/-/g, "/"),
      Open: VPs.value.Prices[index][0],
      High: VPs.value.Prices[index][3],
      Low: VPs.value.Prices[index][2],
      Close: VPs.value.Prices[index][1],
      Volume: VPs.value.Prices[index][4],
      ...sortedKeyList.reduce((acc, key) => {
        if (key in COT.value) {
          acc[key] = COT.value[key][index];
        }
        return acc;
      }, {}),
    }));

    return combinedData;
  }

  init();

  return {
    sym,
    symbol,
    longReptType,
    reportType,
    prevReptType,
    dataType,
    chartType,
    posType,
    valueType,
    seriesType,
    hasCITS,
    getReptType,
    setChartType,
    VPs,
    COT,
    date,
    open,
    high,
    low,
    close,
    volume,
    rangeRst,
    indexRst,
    rangeTab,
    indexTab,
    indexPeriod,
    rstRangeTab,
    rstIndexTab,
    checkIndexTab,
    rstZoomStart,
    zoomStart,
    zoomEnd,
    COTLength,
    rawLength,
    isContainSprd,
    isLoading,
    legNetPosValLegend,
    legNetPctValLegend,
    legRawPosValLegend,
    legRawPctValLegend,
    legNetPosIdxLegend,
    legRawPosIdxLegend,
    legNetPctIdxLegend,
    legRawPctIdxLegend,
    disaggNetPosValLegend,
    disaggRawPosValLegend,
    disaggNetPctValLegend,
    disaggRawPctValLegend,
    disaggNetPosIdxLegend,
    disaggRawPosIdxLegend,
    disaggNetPctIdxLegend,
    disaggRawPctIdxLegend,
    tiffNetPosValLegend,
    tiffRawPosValLegend,
    tiffNetPctValLegend,
    tiffRawPctValLegend,
    tiffNetPosIdxLegend,
    tiffRawPosIdxLegend,
    tiffNetPctIdxLegend,
    tiffRawPctIdxLegend,
    citsNetPosValLegend,
    citsNetPctValLegend,
    citsRawPosValLegend,
    citsRawPctValLegend,
    citsNetPosIdxLegend,
    citsNetPctIdxLegend,
    citsRawPosIdxLegend,
    citsRawPctIdxLegend,
    legLSRValLegend,
    disaggLSRValLegend,
    tiffLSRValLegend,
    citsLSRValLegend,
    refAll,
    refCOT,
    updCOT,
    genCOT,
    prepareDataForExport,
  };
});

export default useChartData;
