import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toDisAggFutNetPosVal(list, isContainSprd) {
  const DisAgg = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.F_OI;
      newObj.Swap_Sprd = obj.F_Swap_Sprd;
      newObj.M_Mny_Sprd = obj.F_M_Mny_Sprd;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Prod_N = obj.F_Prod_L - obj.F_Prod_S;
    newObj.Swap_N = obj.F_Swap_L - obj.F_Swap_S;
    newObj.M_Mny_N = obj.F_M_Mny_L - obj.F_M_Mny_S;
    newObj.OtrRept_N = obj.F_OtrRept_L - obj.F_OtrRept_S;
    newObj.NR_N = obj.F_NR_L - obj.F_NR_S;
    return newObj;
  });
  return fmtCOT(DisAgg);
}
