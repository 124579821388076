import GRequest from "../request/index";

export function getVPs(symbol) {
  return GRequest.get({
    url: "/get_VPs",
    params: {
      symbol: symbol,
      // offset: offset,
    },
  });
}
