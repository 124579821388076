import CryptoJS from "crypto-js";

const dt1 = "19850215";
const dt2 = "20240413";

var key = CryptoJS.enc.Utf8.parse(dt1 + dt2);
var iv = CryptoJS.enc.Utf8.parse(dt2 + dt1);

export function enc(word) {
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
