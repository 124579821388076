export default function fmtCOT(rawData) {
  const result = {};
  for (const obj of rawData) {
    for (const [key, value] of Object.entries(obj)) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
    }
  }
  return result;
}
