import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toDisAggFutNetPctIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.Prod_N = (obj.F_Prod_L - obj.F_Prod_S) / obj.F_OI;
      newObj.Swap_N = (obj.F_Swap_L - obj.F_Swap_S) / obj.F_OI;
      newObj.Swap_Sprd = obj.F_Swap_Sprd / obj.F_OI;
      newObj.M_Mny_N = (obj.F_M_Mny_L - obj.F_M_Mny_S) / obj.F_OI;
      newObj.M_Mny_Sprd = obj.F_M_Mny_Sprd / obj.F_OI;
      newObj.OtrRept_N = (obj.F_OtrRept_L - obj.F_OtrRept_S) / obj.F_OI;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd / obj.F_OI;
      newObj.NR_N = (obj.F_NR_L - obj.F_NR_S) / obj.F_OI;
    } else {
      newObj.Prod_N =
        (obj.F_Prod_L - obj.F_Prod_S) /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Swap_N =
        (obj.F_Swap_L - obj.F_Swap_S) /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.Swap_Sprd = [];
      newObj.M_Mny_N =
        (obj.F_M_Mny_L - obj.F_M_Mny_S) /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_N =
        (obj.F_OtrRept_L - obj.F_OtrRept_S) /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_N =
        (obj.F_NR_L - obj.F_NR_S) /
        (obj.F_OI - obj.F_Swap_Sprd - obj.F_M_Mny_Sprd - obj.F_OtrRept_Sprd);
    }
    return newObj;
  });
  return fmtIdx(Idx, period);
}
