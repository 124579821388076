import GRequest from "../request/index";

export function getLFs(symbol) {
  return GRequest.get({
    url: "/get_LFs",
    params: {
      symbol: symbol,
      // limit: 10000,
    },
  });
}
