import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toTIFFCmbRawPctVal(list, isContainSprd) {
  let TIFF;
  if (isContainSprd) {
    TIFF = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Dlr_L = obj.C_Dlr_L / obj.C_OI;
      newObj.Dlr_S = -obj.C_Dlr_S / obj.C_OI;
      newObj.Dlr_Sprd = obj.C_Dlr_Sprd / obj.C_OI;
      newObj.AssMgr_L = obj.C_AssMgr_L / obj.C_OI;
      newObj.AssMgr_S = -obj.C_AssMgr_S / obj.C_OI;
      newObj.AssMgr_Sprd = obj.C_AssMgr_Sprd / obj.C_OI;
      newObj.LevMny_L = obj.C_LevMny_L / obj.C_OI;
      newObj.LevMny_S = -obj.C_LevMny_S / obj.C_OI;
      newObj.LevMny_Sprd = obj.C_LevMny_Sprd / obj.C_OI;
      newObj.OtrRept_L = obj.C_OtrRept_L / obj.C_OI;
      newObj.OtrRept_S = -obj.C_OtrRept_S / obj.C_OI;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd / obj.C_OI;
      newObj.NR_L = obj.C_NR_L / obj.C_OI;
      newObj.NR_S = -obj.C_NR_S / obj.C_OI;
      return newObj;
    });
  } else {
    TIFF = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Dlr_L =
        obj.C_Dlr_L /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.Dlr_S =
        -obj.C_Dlr_S /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.Dlr_Sprd = [];
      newObj.AssMgr_L =
        obj.C_AssMgr_L /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.AssMgr_S =
        -obj.C_AssMgr_S /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.AssMgr_Sprd = [];
      newObj.LevMny_L =
        obj.C_LevMny_L /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.LevMny_S =
        -obj.C_LevMny_S /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.LevMny_Sprd = [];
      newObj.OtrRept_L =
        obj.C_OtrRept_L /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.OtrRept_S =
        -obj.C_OtrRept_S /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_L =
        obj.C_NR_L /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      newObj.NR_S =
        -obj.C_NR_S /
        (obj.C_OI -
          obj.C_Dlr_Sprd -
          obj.C_AssMgr_Sprd -
          obj.C_LevMny_Sprd -
          obj.C_OtrRept_Sprd);
      return newObj;
    });
  }
  return fmtCOT(TIFF);
}
