import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toTIFFFutNetPosIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.OI = obj.F_OI;
      newObj.Dlr_Sprd = obj.F_Dlr_Sprd;
      newObj.AssMgr_Sprd = obj.F_AssMgr_Sprd;
      newObj.LevMny_Sprd = obj.F_LevMny_Sprd;
      newObj.OtrRept_Sprd = obj.F_OtrRept_Sprd;
    } else {
      newObj.OI =
        obj.F_OI -
        obj.F_Dlr_Sprd -
        obj.F_AssMgr_Sprd -
        obj.F_LevMny_Sprd -
        obj.F_OtrRept_Sprd;
      newObj.Swap_Sprd = [];
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_Sprd = [];
    }
    newObj.Dlr_N = obj.F_Dlr_L - obj.F_Dlr_S;
    newObj.AssMgr_N = obj.F_AssMgr_L - obj.F_AssMgr_S;
    newObj.LevMny_N = obj.F_LevMny_L - obj.F_LevMny_S;
    newObj.OtrRept_N = obj.F_OtrRept_L - obj.F_OtrRept_S;
    newObj.NR_N = obj.F_NR_L - obj.F_NR_S;
    return newObj;
  });
  return fmtIdx(Idx, period);
}
