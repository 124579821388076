import fmtLSR from "./fmtLSR";
import toDates from "./toDates";

export function toTIFFFutLSRVal(list) {
  let LSR;
  LSR = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (obj.F_Dlr_L > obj.F_Dlr_S) {
      if (obj.F_Dlr_S === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = obj.F_Dlr_L / obj.F_Dlr_S;
      }
    } else if (obj.F_Dlr_L < obj.F_Dlr_S) {
      if (obj.F_Dlr_L === 0) {
        newObj.Dlr_R = 0;
      } else {
        newObj.Dlr_R = -(obj.F_Dlr_S / obj.F_Dlr_L);
      }
    } else {
      newObj.Dlr_R = 1;
    }

    if (obj.F_AssMgr_L > obj.F_AssMgr_S) {
      if (obj.F_AssMgr_S === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = obj.F_AssMgr_L / obj.F_AssMgr_S;
      }
    } else if (obj.F_AssMgr_L < obj.F_AssMgr_S) {
      if (obj.F_AssMgr_L === 0) {
        newObj.AssMgr_R = 0;
      } else {
        newObj.AssMgr_R = -(obj.F_AssMgr_S / obj.F_AssMgr_L);
      }
    } else {
      newObj.AssMgr_R = 1;
    }

    if (obj.F_LevMny_L > obj.F_LevMny_S) {
      if (obj.F_LevMny_S === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = obj.F_LevMny_L / obj.F_LevMny_S;
      }
    } else if (obj.F_LevMny_L < obj.F_LevMny_S) {
      if (obj.F_LevMny_L === 0) {
        newObj.LevMny_R = 0;
      } else {
        newObj.LevMny_R = -(obj.F_LevMny_S / obj.F_LevMny_L);
      }
    } else {
      newObj.LevMny_R = 1;
    }

    if (obj.F_OtrRept_L > obj.F_OtrRept_S) {
      if (obj.F_OtrRept_S === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = obj.F_OtrRept_L / obj.F_OtrRept_S;
      }
    } else if (obj.F_OtrRept_L < obj.F_OtrRept_S) {
      if (obj.F_OtrRept_L === 0) {
        newObj.OtrRept_R = 0;
      } else {
        newObj.OtrRept_R = -(obj.F_OtrRept_S / obj.F_OtrRept_L);
      }
    } else {
      newObj.OtrRept_R = 1;
    }

    if (obj.F_NR_L > obj.F_NR_S) {
      if (obj.F_NR_S === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = obj.F_NR_L / obj.F_NR_S;
      }
    } else if (obj.F_NR_L < obj.F_NR_S) {
      if (obj.F_NR_L === 0) {
        newObj.NR_R = 0;
      } else {
        newObj.NR_R = -(obj.F_NR_S / obj.F_NR_L);
      }
    } else {
      newObj.NR_R = 1;
    }
    return newObj;
  });

  return fmtLSR(LSR);
}
