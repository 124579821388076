import toDates from "./toDates";
import fmtIdx from "./fmtIdx";

export function toDisAggCmbNetPctIdx(list, isContainSprd, period) {
  const Idx = list.map((obj) => {
    const newObj = {};
    newObj.Dates = toDates(obj);
    if (isContainSprd) {
      newObj.Prod_N = (obj.C_Prod_L - obj.C_Prod_S) / obj.C_OI;
      newObj.Swap_N = (obj.C_Swap_L - obj.C_Swap_S) / obj.C_OI;
      newObj.Swap_Sprd = obj.C_Swap_Sprd / obj.C_OI;
      newObj.M_Mny_N = (obj.C_M_Mny_L - obj.C_M_Mny_S) / obj.C_OI;
      newObj.M_Mny_Sprd = obj.C_M_Mny_Sprd / obj.C_OI;
      newObj.OtrRept_N = (obj.C_OtrRept_L - obj.C_OtrRept_S) / obj.C_OI;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd / obj.C_OI;
      newObj.NR_N = (obj.C_NR_L - obj.C_NR_S) / obj.C_OI;
    } else {
      newObj.Prod_N =
        (obj.C_Prod_L - obj.C_Prod_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.Swap_N =
        (obj.C_Swap_L - obj.C_Swap_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.Swap_Sprd = [];
      newObj.M_Mny_N =
        (obj.C_M_Mny_L - obj.C_M_Mny_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_N =
        (obj.C_OtrRept_L - obj.C_OtrRept_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_N =
        (obj.C_NR_L - obj.C_NR_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
    }
    return newObj;
  });
  return fmtIdx(Idx, period);
}
