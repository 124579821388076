import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toCITNetPctVal(list, isContainSprd) {
  let CIT;
  if (isContainSprd) {
    CIT = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.NC_N = (obj.S_NC_L - obj.S_NC_S) / obj.S_OI;
      newObj.NC_Sprd = obj.S_NC_Sprd / obj.S_OI;
      newObj.CM_N = (obj.S_CM_L - obj.S_CM_S) / obj.S_OI;
      newObj.NR_N = (obj.S_NR_L - obj.S_NR_S) / obj.S_OI;
      newObj.CIT_N = (obj.S_CIT_L - obj.S_CIT_S) / obj.S_OI;
      return newObj;
    });
  } else {
    CIT = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.NC_N = (obj.S_NC_L - obj.S_NC_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.NC_Sprd = [];
      newObj.CM_N = (obj.S_CM_L - obj.S_CM_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.NR_N = (obj.S_NR_L - obj.S_NR_S) / (obj.S_OI - obj.S_NC_Sprd);
      newObj.CIT_N = (obj.S_CIT_L - obj.S_CIT_S) / (obj.S_OI - obj.S_NC_Sprd);
      return newObj;
    });
  }
  return fmtCOT(CIT);
}
