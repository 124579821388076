import fmtCOT from "./fmtCOT";
import toDates from "./toDates";

export function toDisAggCmbNetPctVal(list, isContainSprd) {
  let DisAgg;
  if (isContainSprd) {
    DisAgg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Prod_N = (obj.C_Prod_L - obj.C_Prod_S) / obj.C_OI;
      newObj.Swap_N = (obj.C_Swap_L - obj.C_Swap_S) / obj.C_OI;
      newObj.Swap_Sprd = obj.C_Swap_Sprd / obj.C_OI;
      newObj.M_Mny_N = (obj.C_M_Mny_L - obj.C_M_Mny_S) / obj.C_OI;
      newObj.M_Mny_Sprd = obj.C_M_Mny_Sprd / obj.C_OI;
      newObj.OtrRept_N = (obj.C_OtrRept_L - obj.C_OtrRept_S) / obj.C_OI;
      newObj.OtrRept_Sprd = obj.C_OtrRept_Sprd / obj.C_OI;
      newObj.NR_N = (obj.C_NR_L - obj.C_NR_S) / obj.C_OI;
      return newObj;
    });
  } else {
    DisAgg = list.map((obj) => {
      const newObj = {};
      newObj.Dates = toDates(obj);
      newObj.Prod_N =
        (obj.C_Prod_L - obj.C_Prod_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.Swap_N =
        (obj.C_Swap_L - obj.C_Swap_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.Swap_Sprd = [];
      newObj.M_Mny_N =
        (obj.C_M_Mny_L - obj.C_M_Mny_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.M_Mny_Sprd = [];
      newObj.OtrRept_N =
        (obj.C_OtrRept_L - obj.C_OtrRept_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      newObj.OtrRept_Sprd = [];
      newObj.NR_N =
        (obj.C_NR_L - obj.C_NR_S) /
        (obj.C_OI - obj.C_Swap_Sprd - obj.C_M_Mny_Sprd - obj.C_OtrRept_Sprd);
      return newObj;
    });
  }
  return fmtCOT(DisAgg);
}
